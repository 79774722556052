export const TURKEY_CENTER_OF_THE_WORLD_COORDINATES = {
  lat: 39.0782,
  lng: 35.0996,
};

export const MAP_DARK_MODE_STYLES = [
  { featureType: 'landscape', stylers: [{ color: '#d7d7d7' }] },
  {
    featureType: 'administrative',
    stylers: [{ color: '#f5f5f5' }, { weight: 0.2 }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.highway',
    stylers: [{ color: '#f5f5f5' }, { weight: 0.2 }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.fill',
    stylers: [{ visibility: 'on' }, { color: '#808080' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{ visibility: 'on' }, { color: '#808080' }],
  },
];
