import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEmpty, isNull } from 'lodash';
import { InView } from 'react-intersection-observer';
import useDeviceType from '@/hooks/useDeviceType';
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap as GoogleMapBase,
  useJsApiLoader,
} from '@react-google-maps/api';
import {
  ICoordinates,
  IGoogleMapProps,
} from '@/components/base/google-map/types';
import {
  MAP_DARK_MODE_STYLES,
  TURKEY_CENTER_OF_THE_WORLD_COORDINATES,
} from '@/components/base/google-map/constants';
import { GoogleMapContext } from './Context';

const GoogleMap: React.FC<IGoogleMapProps> = ({
  options,
  directions: directionsData,
  children,
  ...props
}) => {
  const deviceType = useDeviceType();
  const { isDarkMap } = useContext(GoogleMapContext);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  });

  // states
  const [isInView, setInView] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const [isDirectionsLoaded, setDirectionsLoaded] = useState<boolean>(false);

  // data
  const mapOptions = useMemo<google.maps.MapOptions>(
    () =>
      isLoaded
        ? {
            zoom: 15,
            center: TURKEY_CENTER_OF_THE_WORLD_COORDINATES,
            minZoom: deviceType === 'desktop' ? 6 : 4,
            maxZoom: 19,
            backgroundColor: 'inherit',
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_RIGHT,
            },
            disableDefaultUI: true,
            streetViewControl: false,
            restriction: {
              latLngBounds: {
                north: 43.1071, // Turkey's northern border (top)
                south: 34.0, // Turkey's southern border (bottom)
                west: 15.0, // Turkey's western border (left)
                east: 50.0, // Eastern border of Turkey (right)
              },
              strictBounds: false, // Only within the specified limits
            },
            // scrollwheel: true, // ignore: CTRL + Wheel
            styles: null,
            ...options,
          }
        : {},
    [isLoaded, deviceType] // options -> don't put it in deps because google map will rerender again in again
  );

  // actions
  const onMapUnmount = () => {
    setMap(null);
  };

  const onChangeInView = (inView: boolean) => {
    if (inView) {
      return;
    }

    setInView(inView);
  };

  const directionsServiceOptions =
    useMemo<google.maps.DirectionsRequest | null>(() => {
      if (
        !isLoaded ||
        !directionsData ||
        !directionsData.origin ||
        !directionsData.destination
      ) {
        return null;
      }

      return {
        origin: directionsData.origin,
        destination: directionsData.destination,
        optimizeWaypoints: true,
        language: 'tr',
        travelMode: google.maps.TravelMode.WALKING,
        waypoints: (directionsData.waypoints || []).map(
          (coordinates: ICoordinates) => ({
            location: coordinates,
            stopover: true,
          })
        ),
      };
    }, [directionsData, isLoaded]);

  const directionsCallback = useCallback(
    (
      response: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus
    ) => {
      if (status === 'OK' && response !== null) {
        setDirections(response);
      } else {
        console.error('Directions request failed due to ' + status);
      }
    },
    []
  );

  const onDirectionsRendererUnMount = () => {
    setDirectionsLoaded(false);
  };

  const onDirectionsRendererLoad = () => {
    setTimeout(() => {
      setDirectionsLoaded(true);
    });
  };

  // effects
  useEffect(() => {
    if (map) {
      map.set('styles', isDarkMap ? MAP_DARK_MODE_STYLES : null);
    }
  }, [isDarkMap]);

  useEffect(() => {
    // check if directions are not null and either origin or destination is missing
    if (
      !isNull(directions) &&
      directionsData &&
      (!directionsData.origin || !directionsData.destination)
    ) {
      // if the above condition is true, set directions to null
      setDirections((prevDirections) =>
        isNull(prevDirections) ? prevDirections : null
      );
    }
  }, [directionsData]);

  // renders
  if (!isLoaded) {
    // error
    if (loadError) {
      console.error('Error: GoogleMap:', loadError);
      return loadError ? <div>Error loading map</div> : null;
    }

    return null;
  }

  if (isInView) {
    return <InView onChange={onChangeInView} />;
  }

  return (
    <GoogleMapBase
      onLoad={setMap}
      onUnmount={onMapUnmount}
      mapContainerClassName="google-map-embed"
      options={mapOptions}
      {...props}
    >
      {children}
      {directionsServiceOptions && (
        <DirectionsService
          options={directionsServiceOptions}
          callback={directionsCallback}
        />
      )}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: '#2256d3',
              strokeWeight: 5,
            },
            markerOptions: {
              visible: false,
              icon: {
                url: '/images/map/marker-default.webp',
                scaledSize: new window.google.maps.Size(26, 26), // Marker boyutu
              },
            },
            suppressMarkers: false,
            preserveViewport: isDirectionsLoaded,
          }}
          onUnmount={onDirectionsRendererUnMount}
          onLoad={onDirectionsRendererLoad}
        />
      )}
    </GoogleMapBase>
  );
};

export default GoogleMap;
