import React, { ReactElement, useContext } from 'react';
import Image from '@/components/base/image';
import Icon from '@/components/base/icon';
import { IPointPopup } from '@/components/base/google-map/point-popup/types';
import { functions } from '@wap-client/core';
import Anchor from '../../anchor/Anchor';
import { GoogleMapContext } from '../Context';

const PointPopup: React.FC<IPointPopup> = ({
  data: point,
  onToggleAddRoute,
  // onClose,
}) => {
  const { showActions, isPointSelected } = useContext(GoogleMapContext);

  // data
  const isAdded = isPointSelected(point);

  // actions
  const onClickToggleAddRoute = () => {
    onToggleAddRoute && onToggleAddRoute(point);
  };

  // renders
  const renderWithAnchor = (children: ReactElement) => {
    if (point.anchor) {
      return <Anchor {...point.anchor}>{children}</Anchor>;
    }

    return children;
  };

  return renderWithAnchor(
    <div className="google-map-popup">
      {/* <button className="google-map-popup-close-btn" onClick={onClose}>
        <Icon name="icon-close-2" size="medium" />
      </button> */}
      <div className="google-map-popup-content">
        {point.image && (
          <div className="google-map-popup-content-image">
            <Image source={point.image} />
          </div>
        )}
        <div className="google-map-popup-content-body">
          {point.subtitle && (
            <div className="google-map-popup-content-body-subtitle">
              {point.subtitle}
            </div>
          )}
          {point.title && (
            <div className="google-map-popup-content-body-title">
              {point.title}
            </div>
          )}
        </div>
        {showActions && (
          <button
            className={functions.classnames('google-map-popup-content-button', {
              'google-map-popup-content-button--added': isAdded,
            })}
            onClick={onClickToggleAddRoute}
          >
            <Icon name={isAdded ? 'icon-close-2' : 'icon-add-2'} />
            {isAdded ? (
              <span>Remove Your Route</span>
            ) : (
              <span>Add Your Route</span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default PointPopup;
