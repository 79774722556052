import React, { createContext, ReactElement, useState } from 'react';
import { IGoogleMapContextProps, PointProps } from './types';
import { find, findIndex, isEmpty, reject, uniqBy } from 'lodash';

export const GoogleMapContext = createContext<{
  isDarkMap: boolean;
  setDarkMap: React.Dispatch<React.SetStateAction<boolean>>;
  showPointList: boolean;
  setShowPointList: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRoutePoints: PointProps[];
  showActions?: boolean;
  viewMyRoutes: boolean;
  setViewMyRoutes: React.Dispatch<React.SetStateAction<boolean>>;
  isPointSelected: (point: PointProps) => boolean;
  getSelectedPointOrder: (point: PointProps) => number | null;
  addPointToRoute: (point: PointProps) => void;
  removePointFromRoute: (point: PointProps) => void;
  resetSelectedPoints: () => void;
  togglePointToRoute: (point: PointProps) => void;
}>({
  isDarkMap: false,
  setDarkMap: () => {},
  showPointList: false,
  setShowPointList: () => {},
  selectedRoutePoints: [],
  showActions: false,
  viewMyRoutes: false,
  setViewMyRoutes: () => {},
  isPointSelected: () => false,
  getSelectedPointOrder: () => null,
  addPointToRoute: () => {},
  removePointFromRoute: () => {},
  resetSelectedPoints: () => {},
  togglePointToRoute: () => {},
});

const GoogleMapContextProvider: React.FC<IGoogleMapContextProps> = ({
  showActions,
  children,
}) => {
  //states
  const [isDarkMap, setDarkMap] = useState<boolean>(false);
  const [showPointList, setShowPointList] = useState<boolean>(false);
  const [viewMyRoutes, setViewMyRoutes] = useState<boolean>(false);

  const [selectedRoutePoints, setSelectedRoutePoints] = useState<PointProps[]>(
    []
  );

  // actions
  const isPointSelected = (point: PointProps) => {
    return !!find(selectedRoutePoints, { id: point.id });
  };

  const getSelectedPointOrder = (point: PointProps) => {
    const index = findIndex(selectedRoutePoints, { id: point.id });
    return index !== -1 ? index + 1 : null;
  };

  const addPointToRoute = (point: PointProps) => {
    setSelectedRoutePoints((prevPoints) =>
      uniqBy([...prevPoints, point], 'id')
    );
  };

  const removePointFromRoute = (point: PointProps) => {
    setSelectedRoutePoints((prevPoints) =>
      reject(prevPoints, { id: point.id })
    );
  };

  const resetSelectedPoints = () => {
    setSelectedRoutePoints((prevSelectedRoutePoints) =>
      !isEmpty(prevSelectedRoutePoints) ? [] : prevSelectedRoutePoints
    );
  };

  const togglePointToRoute = (point: PointProps) => {
    const isSelectedPoint = isPointSelected(point);

    // remove from route
    if (isSelectedPoint) {
      removePointFromRoute(point);
    }
    // add to route
    else {
      addPointToRoute(point);
    }
  };

  // effects

  const value = {
    // states
    isDarkMap,
    setDarkMap,
    showPointList,
    setShowPointList,
    selectedRoutePoints,
    viewMyRoutes,
    setViewMyRoutes,

    // data
    showActions,

    // actions
    isPointSelected,
    getSelectedPointOrder,
    addPointToRoute,
    removePointFromRoute,
    resetSelectedPoints,
    togglePointToRoute,
  };

  return (
    <GoogleMapContext.Provider value={value}>
      {children}
    </GoogleMapContext.Provider>
  );
};

export default GoogleMapContextProvider;
