import React, { useContext, useMemo } from 'react';
import { MarkerF, InfoBox } from '@react-google-maps/api';
import { InfoBoxOptions } from '@react-google-maps/infobox';
import { IPointMarker } from '@/components/base/google-map/point-marker/types';
import PointPopup from '@/components/base/google-map/point-popup/PointPopup';
import { isEmpty } from 'lodash';
import { PointProps } from '../types';
import { GoogleMapContext } from '../Context';

const PointMarker: React.FC<IPointMarker> = ({
  point,
  label,
  icon,
  order,
  clusterer,
  showInfoBox,
  onInfoBoxShow,
  onInfoBoxHide,
}) => {
  const { isPointSelected, togglePointToRoute } = useContext(GoogleMapContext);

  // data
  const isSelected = isPointSelected(point);

  // actions
  const onCloseClickInfoView = () => {
    onInfoBoxHide && onInfoBoxHide();
  };

  const handleTogglePointAddToRoute = (point: PointProps) => {
    if (!point) {
      return;
    }

    togglePointToRoute(point);
  };

  const onMarkerClick = () => {
    // if already opened then close the popup
    if (showInfoBox) {
      onCloseClickInfoView();
      return;
    }

    // if the title of point has set then show the popup
    if (point && !isEmpty(point.title)) {
      onInfoBoxShow && onInfoBoxShow(point);
    }
  };

  // data
  const position = useMemo(() => {
    return {
      lat: point.lat,
      lng: point.lng,
    };
  }, [point]);

  const iconOptions = useMemo<google.maps.Icon>(
    () => ({
      url: `/images/map/svg/marker-${isSelected ? 'active' : 'default'}.svg`,
      anchor: new window.google.maps.Point(23, 23),
      scaledSize: new window.google.maps.Size(46, 46), // Marker Size
      labelOrigin: new window.google.maps.Point(11, -20),
      ...icon,
    }),
    [icon, isSelected]
  );

  const labelOptions = useMemo<google.maps.MarkerLabel | undefined>(() => {
    if (label && !isEmpty(label)) {
      return {
        className: 'google-map-pin-label',
        text: label,
      };
    }

    if (order) {
      return {
        className: 'google-map-pin-order',
        text: String(order),
      };
    }

    return {
      className: 'google-map-pin-hidden',
      text: '',
    };
  }, [label, order]);

  const infoBoxOptions: InfoBoxOptions = useMemo(
    () => ({
      boxStyle: { overflow: 'unset' },
      closeBoxURL: '',
      maxWidth: 184,
      infoBoxClearance: new window.google.maps.Size(40, 40),
      pixelOffset: new window.google.maps.Size(0, -10),
      alignBottom: true,
      visible: true,
    }),
    []
  );

  return (
    <MarkerF
      position={position}
      icon={iconOptions}
      clusterer={clusterer}
      label={labelOptions}
      onClick={onMarkerClick}
    >
      {showInfoBox && (
        <InfoBox options={infoBoxOptions} onCloseClick={onCloseClickInfoView}>
          <PointPopup
            data={point}
            onToggleAddRoute={handleTogglePointAddToRoute}
            onClose={onCloseClickInfoView}
          />
        </InfoBox>
      )}
    </MarkerF>
  );
};

export default PointMarker;
